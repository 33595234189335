import JSEncrypt from 'jsencrypt';


const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqR+vQzWG4s4UW9rGA7fESIPjzHBS2mCDeqHO/fTqn30FaJWjB3iSC7PPUaMWNcWW1TEbW5M84dTob/liqP62+3CbKzT4I8CnWFkMmJ6Q9ao9+fqSIjTQTsbTeY7Gp4GxQD5LiVNFnRr1J3Go7hQxE5WGsmW1qjMsmmM8RXWlqtChM4Whpq24ObXW80EzZY8CrL7Z+f75aKrNf8ex0FuestaVLBIpooJOX8bEQP8BaxpbRdvq9fx6ORZKamTkBxwsUNy5DR8+ptIm/ii5tXYUePaisg7Eo/4jXufr0I+yuN9r5D2pJeQUw7yJd2KZ6OvO3pWeGFWAu70+JDK6YNbxMwIDAQAB
-----END PUBLIC KEY-----
`;
const privateKey = `-----BEGIN RSA PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCeZYuJoKs5erbGv5zMuLhscHkUQ2X33Q5/HMdNFB9X0OjtaeCFG6jgUGE8uCiJGuGp1yUS1D2j77QRpEJIpVwLOy91IpGbqtrgJibP5SzrMo3BJyfI3KT8ew3F10XMP6H93sqZWkC8E8BaD/5644KbQxPjtBEiDW4a0ADrQqrpR+abx45GZLnXTvK9uc8g+EZvvF5Lnqm5flSExjDRzfhZ/vVigXyRAg8Tbu/Se3ZgTZQY7Bd3lc5vb6SVuDjB2rhKThsNNelZYvGPzhBkHaOoWEg+mytrmhawpViIou5+Hc21daCjm1h9kgWyfjdxz9mnBssGcszpjUWLvIGJfW5zAgMBAAECggEAeFjq9jyvG9MTAFshxaIr7ahfNngnB3Zbx1SUUQoS7mP6NjiVsEzduOEZoErD8bgM8UBYGMBNvTso86Z7/jo1fwsO+c60FzRvbJNQNfEIegNFFRas8b5DVNoiEsmHbLKEYCFgibe7K6tOIJ+6WKB34YPLntx3kAnoOFMF/7n9pZQWDV2SPYlLdxp4371qVp/lN8NvNh1T4vD22zun1O4JXVKpco1zUyYBo4fuiQxObWXaMTk8mFix/WX5G8Bf8MLQtJcD3G3dz1FkJ+H2VAq0Q91LYDJAwlFlnhbZ5+T71erp1b3hO94eEuost/i0daaRyGiN06XqLHV9pUwpGnPaYQKBgQDz7CjvW0qyeCA+IGNPR3N3tEqZrA+HgYZyio3isrQBGLPG6rhqSllId3TlRtiAYWuOF8vNJ3Ekm9yHKtsTXfdMYS5uplyaSJtRLYg2MuOwKA4xy55VASJcIUxprwTltKK3S7Jyvh+V/+OoQcDFYquUW5nDlENTSTqr4ZKMohIfuQKBgQCmPU1fHvKfIy9no8Xt0cEf7Gp7rrkLaTTGorbix+hcuYZ0k70Sj5MwaCqFrpefRpSULFCjjksgwxecYAypla6VTNqS2logBIRcqv/ZUXoSBsuWcld+A/ZGLTCF+fYIPMiLveeLASgXqivvYzN8H65DThuctCswMq78m5rlZSBdiwKBgBiFGOhWyP9/y93y64jAqf7ShN1FypnYj2dcPj8Xr7icfxXz6m3k42zBdWLG6GSfZmx2P+pzmJARiu2bhUJBI+btrfk5h/2KZvKWHSc1nmXK4QJocZYh8w5H2qKVEWz155V9aobWTd6KRpxXX8V30b+sJZzffpUUu20j09OHR0MhAoGBAKCeUFhclX1OyKmxYjbi2F8Y1NWFvsKYtuZJJr08+3RDyPZwHFmHoWY+7v7U7yh0UHBN1OgCPvJ7rR9o6jMcLV+Rrwn+sN+Inb7PdX4qq/Ao4qIM/h4Cg/vQA7cfCSlyWS4zoTj8OBmNUP/t8Du4fG4JVMJ2jdf20H2/dsy3har7AoGBAOVL8uqfYK69mksdHrU6vMJSZbxUdrbpQFZvFavyF5NaX4gdASno+YzFEpzclAzgJtYuXmcn5ik0x7XyoHtDaim2iBcA3ORohQVyfUok/jLlyUjBdflMXgODNpLUnChQ4c/Uijva0ESMk3IeZvkIUFIkUWvnCHlgMLFQwSonMIVU\n
-----END RSA PRIVATE KEY-----
`;
class JSEncryptUtil {
  constructor() {
    this.encryptor = new JSEncrypt();
    this.encryptor.setPublicKey(publicKey);
    this.decryptor = new JSEncrypt();
    this.decryptor.setPrivateKey(privateKey);
    this.maxEncryptLength = 245; // 根据实际情况调整
  }

  // 分割数据
  chunkData(data, length) {
    const chunks = [];
    for (let i = 0; i < data.length; i += length) {
      chunks.push(data.substring(i, i + length));
    }
    return chunks;
  }

  // 加密数据
  encrypt(data) {
    const chunks = this.chunkData(data, this.maxEncryptLength);
    const encryptedChunks = chunks.map(chunk => this.encryptor.encrypt(chunk));
    return encryptedChunks.join('|'); // 使用某种分隔符合并加密后的数据
  }

  // 解密数据
  decrypt(encryptedData) {
    const encryptedChunks = encryptedData.split('|'); // 使用同样的分隔符拆分
    const decryptedChunks = encryptedChunks.map(chunk => this.decryptor.decrypt(chunk));
    return decryptedChunks.join('');
  }
}

export default new JSEncryptUtil();