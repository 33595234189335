<template>
  <div class="register-container">
    <div class="header">
      <div style="justify-content: center; align-items: center; display: flex;">
        <img src="@/assets/logo.png" class="logo" />
        <span style="margin-left: 10px;">小助手</span>
      </div>

      <p style="display: none;">客服支持</p>
    </div>
    <div class="register">
      <h3 class="title">小助手 用户注册</h3>
      <el-form :model="form" :rules="rules" status-icon ref="registerForm" label-position="left" class="demo-ruleForm">
        <el-form-item prop="username" label="手机号码">
          <el-input type="text" v-model="form.username" autocomplete="off" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input type="password" v-model="form.password" autocomplete="off" placeholder="请输入密码"
            show-password></el-input>
        </el-form-item>
        <el-form-item prop="company" label="公司名称">
          <el-input type="company" v-model="form.company" autocomplete="off" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item prop="realName" label="真实姓名">
          <el-input type="realName" v-model="form.realName" autocomplete="off" placeholder="请输入真实姓名"></el-input>
        </el-form-item>

        <el-button type="primary" @click="handleSubmit" :loading="loading">注册</el-button>
        <el-button type="info" @click="$router.push('/login')">已有账号？去登录</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import userService from '@/api/user'; // 根据实际路径引入

export default {
  name: 'Register',
  data() {
    return {
      form: {
        username: '',
        company: '',
        password: '',
        realName: ''
      },
      loading: false,
      rules: {
        username: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 16, message: '密码长度应在6到16个字符之间', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    async handleSubmit() {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          this.handleRegister();
        } else {
          return false;
        }
      });
    },
    async handleRegister() {
      this.loading = true;
      try {
        const response = await userService.register(this.form.username, this.form.company, this.form.password, this.form.realName);
        if (response.code == 1) {
          this.$store.dispatch('saveToken', response.data.accessToken);
          this.$store.dispatch('saveUser', response.data);
          this.$router.push('/');
        } else {
          this.$message({
            message: response.msg,
            type: 'error'
          });
        }
      } catch (error) {
        this.$message({
          message: '注册失败，请检查您的输入。',
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.register {
  width: 40%;
  margin: 0 auto;
  margin-top: 100px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
  width: 96%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}

.logo {
  width: 50px;
  /* 调整宽度以适应您的 logo */
  height: auto;
  margin-left: 20px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  margin-top: 10px;
}
</style>