import axios from 'axios';
import { Message } from 'element-ui'; // 假设你使用的是 Element UI 库来显示消息
import router from '@/router';

// 设置基础 URL
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || '/api';

// 设置请求超时时间
axios.defaults.timeout = 10000;


// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 获取当前请求的URL
    const url = config.url;
    // 检查是否为登录或注册请求，如果是，则不添加Authorization头
    if (!(url.includes('/login') || url.includes('/register'))) {
      const token = localStorage.getItem('user-token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  function (error) {
    // 请求错误处理
    console.error('请求错误:', error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    if (response.data.code == 107) {
      router.push('/login');
    }
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 响应错误处理
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 处理未授权状态
          break;
        case 403:
          // 处理禁止访问状态
          break;
        case 404:
          // 处理找不到资源的状态
          break;
        default:
          Message.error('服务器错误');
      }
    } else if (error.request) {
      // 客户端从未收到过响应的情况
      Message.error('无法连接到服务器，请检查网络');
    } else {
      // 发送请求之前就发生的错误
      Message.error('请求发生错误，请稍后再试');
    }
    return Promise.reject(error);
  }
);

const request = options => {
  return new Promise((resolve, reject) => {
    axios(options)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default {
  get(url, params) {
    return request({ method: 'get', url, params });
  },
  post(url, data) {
    return request({ method: 'post', url, data });
  },
  put(url, data) {
    return request({ method: 'put', url, data });
  },
  delete(url, params) {
    return request({ method: 'delete', url, params });
  }
};