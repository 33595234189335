// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import store from '@/store';
import userService from '@/api/user'; // 根据实际路径引入
import JSEncryptUtil from '@/utils/JSEncryptUtil';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  let tokenInURL = to.query.token;
  if (tokenInURL) {
    try {
      tokenInURL = decodeURIComponent(tokenInURL);
      let data = JSEncryptUtil.decrypt(tokenInURL);
      let jsonObject = JSON.parse(data);
      let token = jsonObject.accessToken;
      console.log(jsonObject);
      console.log(token);
      store.dispatch('saveToken', token);
      const response = await userService.check(token);
      if (response.code == 1) {
        store.dispatch('saveToken', response.data.accessToken);
        store.dispatch('saveUser', response.data);
        next('/');
      } else {
        Vue.prototype.$message({
          message: response.msg,
          type: 'error'
        });
      }

    } catch (error) {
      Vue.prototype.$message({
        message: '登录失败，请检查您的账号。',
        type: 'error'
      });
    }


  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.getToken) {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        });
      } else {
        next();
      }
    } else {
      next(); // 确保一定要调用 next()！
    }
  }
});

export default router;