// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('user-token') || null,
    user: localStorage.getItem('boss-user') || null
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('user-token', token); // 保存到本地存储
    },
    REMOVE_TOKEN(state) {
      state.token = null;
      localStorage.removeItem('user-token'); // 从本地存储移除
    },
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('boss-user', user); // 保存到本地存储
    },
    REMOVE_USER(state) {
      state.user = null;
      localStorage.removeItem('boss-user'); // 从本地存储移除
    }
  },
  actions: {
    saveToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    saveUser({ commit }, user) {
      commit('SET_USER', user);
    },
    logout({ commit }) {
      commit('REMOVE_TOKEN');
      commit('REMOVE_USER');
    }
  },
  plugins: [
    createPersistedState({
      // 可选配置项
      key: 'boss-user', // 存储的 key 名称
      paths: ['user', 'token'], // 指定需要持久化的状态路径
      storage: window.localStorage, // 存储位置，默认是 localStorage
    }),
  ],
  getters: {
    getToken: state => state.token,
    getUser: state => state.user
  }
});