import request from '@/utils/request';
import JSEncryptUtil from '@/utils/JSEncryptUtil';

const login = async (username, password) => {
  let type = 1;
  const data = JSON.stringify({ username, password, type });
  const encryptData = JSEncryptUtil.encrypt(data);
  const response = await request.post('/login', { data: encryptData });
  if (response.code == 1) {
    const decryptedData = JSEncryptUtil.decrypt(response.data);
    response.data = JSON.parse(decryptedData);
  }
  return response;
};

const check = async (token) => {
  let type = 1;
  const data = JSON.stringify({ token, type });
  const encryptData = JSEncryptUtil.encrypt(data);
  const response = await request.post('/check', { data: encryptData });
  if (response.code == 1) {
    const decryptedData = JSEncryptUtil.decrypt(response.data);
    response.data = JSON.parse(decryptedData);
  }
  return response;
};

const register = async (username, company, password, realName) => {
  const data = JSON.stringify({ username, company, password, realName });
  const encryptData = JSEncryptUtil.encrypt(data);
  const response = await request.post('/register', {
    data: encryptData
  });
  if (response.code == 1) {
    const decryptedData = JSEncryptUtil.decrypt(response.data);
    response.data = JSON.parse(decryptedData);
  }
  return response;
};

const changePassword = async (oldPassword, newPassword) => {
  const data = JSON.stringify({ oldPassword, newPassword });
  const encryptData = JSEncryptUtil.encrypt(data);
  const response = await request.post('/changePassword', {
    data: encryptData
  });
  return response;
};




const logout = async () => {
  const response = await request.get('/exit');
  return response;
};

export default {
  login, register, logout, changePassword, check
};




