<!-- src/views/MemberCenter.vue -->
<template>
  <div>
    <el-container>
      <div class="header">
        <div style="justify-content: center; align-items: center; display: flex;">
          <img src="@/assets/logo.png" class="logo" />
          <span style="margin-left: 10px;">小助手</span>
        </div>

        <p style="display: none;">客服支持</p>
        <el-dropdown @command="handleCommand">
          <span class="account" style="cursor: pointer;">
            <span class="user-name">{{ user.name }}</span><el-avatar :size="30" :src="circleUrl"></el-avatar>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-main>
        <el-card>
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div style="display: flex; align-items: center;">
              <el-avatar :size="50" :src="circleUrl"></el-avatar>
              <div style="margin-left: 20px; text-align: left;">
                <span class="user-name" style="display: block; font-weight: bold;">{{ user.name }}</span>
                <span class="user-name" style="display: block; font-size: 15px; margin-top: 5px;">{{ userType }}</span>
              </div>
            </div>
            <span @click="showChangePasswordDialog = true"
              style="margin-right: 150px;cursor: pointer;color: blue;">修改密码</span>
          </div>
        </el-card>
      </el-main>
      <el-dialog :visible.sync="showChangePasswordDialog" title="修改密码" width="30%" center>
        <el-form :model="changePasswordForm" :rules="changePasswordRules" ref="changePasswordFormRef" label-width="80px">
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input v-model="changePasswordForm.oldPassword" type="password" show-password></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input v-model="changePasswordForm.newPassword" type="password" show-password></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showChangePasswordDialog = false">取 消</el-button>
          <el-button type="primary" @click="submitChangePassword">确 定</el-button>
        </span>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import userService from '@/api/user'; // 根据实际路径引入

export default {
  data() {
    return {
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      showChangePasswordDialog: false,
      changePasswordForm: {
        oldPassword: '',
        newPassword: ''
      },
      changePasswordRules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 16, message: '密码长度应在6到16个字符之间', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    user() {
      return this.$store.state.user || '用户11111';
    },
    userType() {
      return getUserType(this.user.type);
    }
  },
  methods: {
    async handleCommand(command) {
      if (command == 'logout') {
        const response = await userService.logout();
        if (response.code == 1) {
          this.$message({
            message: response.msg,
            type: 'success'
          });
          this.$store.dispatch('logout');
          this.$router.push('/login');
        } else {
          this.$message({
            message: response.msg,
            type: 'error'
          });
        }
      }
    },
    submitChangePassword() {
      this.$refs.changePasswordFormRef.validate(async valid => {
        if (valid) {
          const response = await userService.changePassword(this.changePasswordForm.oldPassword, this.changePasswordForm.newPassword);
          if (response.code == 1) {
            this.$message({
              message: response.msg,
              type: 'success'
            });
            this.$store.dispatch('logout');
            this.$router.push('/login');
          } else {
            this.$message({
              message: response.msg,
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};

const userType = {
  1: '注册用户',
  2: '普通用户',
  3: '高级用户',
  4: '终身用户',
};

function getUserType(type) {
  return userType[type] || '未知用户类型';
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
  width: 96%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}

.logo {
  width: 50px;
  /* 调整宽度以适应您的 logo */
  height: auto;
  margin-left: 20px;
}

.account {
  display: flex;
  align-items: center;
}

.user-name {
  margin-right: 10px;
}

.price {
  color: red;
}
</style>