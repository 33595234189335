<template>
  <div class="login-container">
    <div class="header">
      <div style="justify-content: center; align-items: center; display: flex;">
        <img src="@/assets/logo.png" class="logo" />
        <span style="margin-left: 10px;">小助手</span>
      </div>

      <p style="display: none;">客服支持</p>
    </div>
    <div class="login">
      <el-form :model="form" :rules="rules" status-icon ref="loginForm" label-position="left" class="demo-ruleForm">
        <h3 class="title">欢迎登录 小助手</h3>
        <el-form-item prop="username" label="手机号码">
          <el-input type="tel" v-model="form.username" autocomplete="off" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input type="password" v-model="form.password" autocomplete="off" placeholder="请输入密码"
            show-password></el-input>
        </el-form-item>
        <el-button type="primary" @click="handleSubmit" :loading="loading">登录</el-button>
        <el-button type="info" @click="handleRegister">注册</el-button>
      </el-form>

    </div>
  </div>
</template>

<script>
import userService from '@/api/user'; // 根据实际路径引入

export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      loading: false,
      rules: {
        username: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    async handleSubmit() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.handleLogin();
        } else {
          return false;
        }
      });
    },
    async handleLogin() {
      this.loading = true;

      try {
        const response = await userService.login(this.form.username, this.form.password);
        if (response.code == 1) {
          this.$store.dispatch('saveToken', response.data.accessToken);
          this.$store.dispatch('saveUser', response.data);
          this.$router.push('/');
        } else {
          this.$message({
            message: response.msg,
            type: 'error'
          });
        }

      } catch (error) {
        this.$message({
          message: '登录失败，请检查您的账号。',
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    },
    handleRegister() {
      this.$router.push('/register');
    }
  }
};
</script>

<style scoped>
.login {
  width: 40%;
  margin: 0 auto;
  margin-top: 200px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
  width: 96%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}

.logo {
  width: 50px;
  /* 调整宽度以适应您的 logo */
  height: auto;
  margin-left: 20px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
</style>








